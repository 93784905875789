import React from "react";
import { useAuth } from "../../hooks/auth";
import { Navigate } from "react-router-dom";

const HubPage: React.FC = () => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    fetch("https://itzakupki.ru/api/auth/v1/users/logout/", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.ok) {
        logout();
      }
    });
  };

  if (!user) {
    return <Navigate to="/auth" />;
  }

  return (
    <div className="p-5 text-white">
      <p>Главная страница</p>
      <p>
        <button onClick={handleLogout}>Выйти</button>
      </p>
    </div>
  );
};

export default HubPage;
