import React from "react";
import { useAuth } from "../../hooks/auth";
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
  const { user } = useAuth();

  const link = user ? (
    <Link to="hub" className="text-white underline">
      Перейти в Личный кабинет
    </Link>
  ) : (
    <Link to="auth" className="text-white underline">
      Войти
    </Link>
  );

  return (
    <div className="p-5 text-center">
      <h1 className="text-white text-3xl">Домашняя страница</h1>
      <br />
      <div>{link}</div>
    </div>
  );
};

export default HomePage;
