import { ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};

const Section: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="p-5 rounded-lg bg-[#262427]">
      <h2 className="mb-2 text-lg text-white">{title}</h2>
      {children}
    </div>
  );
};

export default Section;
