import React from "react";
import Add from "../../Plans/Toolbar/Add";

const OrgPurchaseMethodsPage: React.FC = () => {
  return (
    <div className="flex flex-col text-white h-full">

      <div className="bg-[#111111]">
        <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
          <div className="w-60 h-20 my-auto content-center ">
            <p className="text-sm text-white">Реестр способов закупки</p>
          </div>
        </div>
      </div>

      <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5"> 

        <div className="flex flex-col h-full w-full space-y-10 bg-slate-600">

          {/* // body */}
          <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
              {/* table */}
              <div className="flex flex-col w-full min-w-fit bg-[#1D1C1E] rounded">
                  {/* toolbar */}
                  <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t">                  
                    <Add color={'#FFFFFF'}/>       
                  </div>

                  {/* name */}
                  <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                      <div className="grid w-10 place-items-center text-center">№</div>
                      <div className="grid flex-1 place-items-center text-center">Наименование способа</div>
                      <div className="grid w-24 place-items-center text-center">Статус</div>
                      <div className="grid w-40 place-items-center text-center">Тип способа закупки</div>
                      <div className="grid w-40 place-items-center text-center">Вид способа закупки</div>
                      <div className="grid w-40 place-items-center text-center">Вид типового способа</div>
                      <div className="grid w-40 place-items-center text-center">Тип конкурентности</div>
                      <div className="grid w-40 place-items-center text-center">Форма проведения</div>
                      <div className="grid w-40 place-items-center text-center">Ограничение по МСП</div>
                      <div className="grid w-20 place-items-center text-center">Дата изменения</div>
                  </div>
                  
                  
                  {/* data */}
                  <div className="flex flex-col overflow-y-auto gap-1 my-1 h-dvh">
                                  
                    {/* Вывод пользователей */}
                    <>


                      <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">                                       
                        <div className="grid w-10 place-items-center text-center text-[#8A8A8A]">
                          1
                        </div>
                        <div className="grid flex-1 place-items-center justify-items-start text-left px-2 py-2">
                          Конкурс в электронной форме, участниками которого могут быть только субъекты малого и среднего предпринимательства
                        </div>
                        <div className="grid w-24 place-items-center text-center px-2 bg-[#187A33] rounded">
                          Активный
                        </div>
                        <div className="grid w-40 place-items-center text-center px-2 ">
                          Типовой
                        </div>
                        <div className="grid w-40 place-items-center text-center px-2 ">
                          Открытый
                        </div>
                        <div className="grid w-40 place-items-center text-center px-2 ">
                          Конкурс
                        </div>
                        <div className="grid w-40 place-items-center text-center px-2 ">
                          Конкурентный
                        </div>
                        <div className="grid w-40 place-items-center text-center px-2 ">
                          В электронной форме
                        </div>
                        <div className="grid w-40 place-items-center text-center ">
                          МСП
                        </div>
                        <div className="grid w-20 place-items-center text-center text-[#8A8A8A] py-2">
                          13.03.2024 <br /> 12:57
                        </div>
                      </div> 

                    </> 
                  </div>

                  {/* buttons */}
                  <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10 space-x-2">
                    <button className="rounded-full px-4 h-6 text-[12.8px] bg-[#494849] hover:bg-[#656365]">+ Создать способ закупки</button>
                    <button className="rounded-full px-4 h-6 text-[12.8px] bg-[#3D3B3F] text-[#8A8A8A]" disabled>Редактировать</button>
                    <button className="rounded-full px-4 h-6 text-[12.8px] bg-[#3D3B3F] text-[#8A8A8A]" disabled>Удалить</button>
                  </div>
              </div>
          </div>

        </div>

      </div>

      
    </div>
  );
};

export default OrgPurchaseMethodsPage;
