import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";

type OrgListItemProps = {
  id: string;
  name: string;
  inn: number;
  kpp: number;
  address: string;
};

const OrgListItem: React.FC<{ item: OrgListItemProps }> = ({ item }) => {
  return (
    <>
      <p className="mb-0 text-sm text-white">{item.name}</p>
      <p className="mb-0 text-xs text-zinc-500">
        ИНН {item.inn} КПП {item.kpp}
      </p>
      <p className="mb-0 text-xs text-zinc-500">Адрес: {item.address}</p>
    </>
  );
};

const SearchForm: React.FC = () => {
  const [query, setQuery] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState<OrgListItemProps[] | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
  };

  const submit = () => {
    setLoading(true);

    const encodedQuery = encodeURIComponent(query);
    const params = isNaN(Number(encodedQuery))
      ? "name=" + encodedQuery
      : "inn=" + encodedQuery + "&ogrn=" + encodedQuery;

    fetch("https://itzakupki.ru/api/auth/v1/organizations/?" + params, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          throw new Error("Search query failed");
        }
      })
      .then((r: OrgListItemProps[]) => {
        setResults(r);
      })
      .catch((e) => {
        console.error(e);
        setResults([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <h5 className="mb-2 text-lg text-white">Регистрация организации</h5>
      <form method="post" onSubmit={handleSubmit}>
        <fieldset disabled={isLoading}>
          <div
            className={
              "flex align-center p-2 border-0 text-sm bg-neutral-900 text-white " +
              (results !== null && results.length > 0
                ? "rounded-t-md"
                : "rounded-md")
            }
          >
            <input
              name="query"
              type="text"
              className="grow border-0 bg-inherit outline-none"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              required={true}
              placeholder="Наименование организации / ИНН / ОГРН"
            />
            <MagnifyingGlassIcon
              style={{ cursor: "pointer" }}
              className="h-6 w-6"
              onClick={submit}
            />
          </div>
        </fieldset>
      </form>
      <div style={{ height: "220px" }}>
        {results !== null && results.length > 0 && (
          <ul className="h-full overflow-y-auto border border-zinc-700">
            {results.map((org: OrgListItemProps) => (
              <li>
                <Link
                  to={`../register/` + encodeURIComponent(org.id)}
                  className="block p-2 hover:bg-zinc-700"
                >
                  <OrgListItem item={org} />
                </Link>
              </li>
            ))}
          </ul>
        )}
        {results !== null && results.length === 0 && (
          <div className="flex flex-col h-full">
            <div className="flex grow justify-center items-center text-center text-sm text-zinc-500">
              <p>
                Организация не найдена
                <br />
                Направьте заявку на добавление организации в реестр заказчиков
              </p>
            </div>
            <Link
              to={`../register/new`}
              className="p-2 bg-fuchsia-600 rounded-full shadow-md text-center text-sm text-white disabled:bg-fuchsia-900 disabled:text-gray-400"
            >
              Создать заявку
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchForm;
