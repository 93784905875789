import React, { useState, ReactElement } from "react";
import { Outlet } from "react-router-dom";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import {
  AcademicCapIcon,
  Cog6ToothIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";

type TextLink = {
  link: string;
  text: string;
};

const MENU_KEY_ORG = "org";
const MENU_KEY_USER = "user";

const SUB_MENU_ITEMS = new Map<string, TextLink[]>([
  [
    MENU_KEY_ORG,
    [
      { link: "org", text: "Организация" },
      { link: "org/users", text: "Пользователи" },
      { link: "org/structure", text: "Структура" },
      { link: "org/roles", text: "Роли" },
      { link: "org/methods", text: "Способы закупки" },
      { link: "org/lists", text: "Перечни" },
    ],
  ],
  [
    MENU_KEY_USER,
    [
      { link: "", text: "Главная страница" },
      { link: "plans", text: "План закупок" },
    ],
  ],
]);

const HubInnerLayout: React.FC = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState(MENU_KEY_ORG);

  return (
    <div className="h-full flex flex-row">
      <div className="h-full flex flex-col p-1 items-center bg-[#181818]">
        <img src={logo} alt="Логотип" className="w-10 h-10 mb-4" />
        <ul className="flex flex-col gap-y-1 grow items-center">
          <MenuTextItem
            text="Орг"
            isActive={currentMenuItem === MENU_KEY_ORG}
            onClick={() => {
              setCurrentMenuItem(MENU_KEY_ORG);
            }}
          />
          <li className="h-px w-6 bg-[#464447] rounded" />
          <MenuTextItem
            text="223"
            isActive={currentMenuItem === MENU_KEY_USER}
            onClick={() => {
              setCurrentMenuItem(MENU_KEY_USER);
            }}
          />
          <li className="h-px w-6 bg-[#464447] rounded" />
          <MenuIconItem
            link=""
            icon={<AcademicCapIcon className="w-4 h-4" />}
          />
          <MenuIconItem link="" icon={<WrenchIcon className="w-4 h-4" />} />
        </ul>
        <div className="flex w-10 h-10 rounded-full items-center justify-center bg-amber-700 text-sm text-white">
          ИИ
        </div>
      </div>
      <div className="h-full flex flex-col min-w-36 p-2 bg-[#262427]">
        <div className="mt-1 mb-6 text-white uppercase leading-none">
          Virtual
          <br />
          Office
        </div>
        <ul className="flex flex-col grap-y-1 grow">
          {(SUB_MENU_ITEMS.get(currentMenuItem) as TextLink[]).map(
            (item, index) => (
              <SubMenuItem key={index} link={item.link} text={item.text} />
            )
          )}
        </ul>
        <Link
          to=""
          className="flex flex-row h-10 items-center p-2.5 rounded text-xs text-zinc-500 hover:bg-[#464447] hover:text-white"
        >
          <div className="grow">Иванов И.И.</div>{" "}
          <Cog6ToothIcon className="h-5 w-5" />
        </Link>
      </div>
      <main className="flex flex-1 flex-col">
        <Outlet />
      </main>
    </div>
  );
};

const MenuTextItem: React.FC<{
  text: string;
  isActive: boolean;
  onClick: () => void;
}> = ({ text, isActive, onClick }) => {
  return (
    <li>
      <span
        className={
          "flex w-12 h-12 rounded-lg items-center p-1 cursor-pointer hover:bg-[#464447]" +
          (isActive ? " bg-[#464447]" : "")
        }
        onClick={onClick}
      >
        <div className="flex w-full aspect-square rounded-full bg-[#262427] items-center justify-center text-zinc-500 text-xs">
          {text}
        </div>
      </span>
    </li>
  );
};

const MenuIconItem: React.FC<{ link: string; icon: ReactElement }> = ({
  link,
  icon,
}) => {
  return (
    <li>
      <Link
        to={link}
        className="flex w-12 h-12 rounded-lg items-center p-1 hover:bg-[#464447] "
      >
        <div className="flex w-full aspect-square rounded-full bg-[#262427] items-center justify-center text-zinc-500">
          {icon}
        </div>
      </Link>
    </li>
  );
};

const SubMenuItem: React.FC<TextLink> = ({ link, text }) => {
  return (
    <li>
      <Link
        to={link}
        className="block p-2.5 rounded text-xs text-zinc-500 hover:bg-[#464447] hover:text-white"
      >
        {text}
      </Link>
    </li>
  );
};

export default HubInnerLayout;
