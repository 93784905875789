type Props = {
  value: Date;
  onChange: (value: Date) => void;
};

const CurrencyDateSelector: React.FC<Props> = (props) => {
  return (
    <>
      <span className="form-label">Дата курса валюты</span>
      <input
        type="date"
        className="form-select focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
        style={{ colorScheme: "dark" }}
        value={_getDateAsString(props.value)}
        onChange={(e) => props.onChange(new Date(e.target.value))}
      />
    </>
  );
};

const _getDateAsString = (date: Date): string => {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const addLeadingZero = (value: number): string => {
    return (value < 10 ? "0" : "") + value;
  };

  return (
    date.getFullYear() + "-" + addLeadingZero(month) + "-" + addLeadingZero(day)
  );
};

export default CurrencyDateSelector;
