import { useEffect, useState } from "react";
import PurchaseItemsTableRow from "./PurchaseItemsTableRow";
import { CubeIcon, FunnelIcon } from "@heroicons/react/20/solid";
import Copy from "../../../../../Toolbar/Copy";

type LayoutProps = {
  planGuid2: string;
};

const PurchaseItemsTable: React.FC<LayoutProps> = (planGuid2) => {

  const [rows, setRows] = useState([_getRowId()]);
  const [totalCount, setTotalCount] = useState(0);

  function _addNewRow() {
    setRows([...rows, _getRowId()]);
  }


  return (

        <div className="flex flex-col w-full min-w-fit mt-5 bg-[#242225] rounded">
            {/* toolbar */}
            <div className="flex flex-row gap-1 place-items-center text-white p-2 h-12 bg-[#333033] rounded-t ">
                
                <div className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                  <input 
                    type="checkbox" 
                    className="p-2" 
                  />
                </div>

                <button onClick={_addNewRow} className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                  <svg className="plus-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="plus" >
                    <path fill='#FFFFFF' d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z">
                    </path>
                  </svg>
                </button>

                <Copy color={'#FFFFFF'}/>                
                
                <button className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                  <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" id="trash-alt">
                    <path fill="#FFFFFF" d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"></path>
                  </svg>
                </button>

                <div className="flex flex-1 gap-1 text-[10px] text-white place-items-end text-center ">

                </div>
                <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center border-r border-r-[#464447]">
                    Позиций <br />
                    {rows.length}
                </div>
                <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                    Количество (объем) <br />
                    {totalCount}
                </div>
            </div>
            {/* name */}
            <div className="flex flex-row gap-1 h-16 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                <div className="grid w-8 place-items-center text-center"></div>
                <div className="grid w-12 place-items-center text-center">№</div>
                <div className="flex-1 grid place-items-center text-center min-w-72">Наименование</div>
                <div className="grid w-8 place-items-center text-center">R</div>
                <div className="grid w-28 place-items-center text-center">Количество (объем)</div>
                <div className="grid w-32 place-items-center text-center">Единица измерения</div>
                <div className="grid w-28 place-items-center text-center">Тип объекта закупки</div>
                <div className="grid w-28 place-items-center text-center">ОКПД2</div>
                <div className="grid w-28 place-items-center text-center">ОКВЭД2</div>
                <div className="grid w-44 place-items-center text-center">Регион</div>
                <div className="grid w-8 place-items-center text-center"></div>
            </div>
            {/* date */}
            <div className="flex flex-col overflow-y-auto gap-1 my-1">
                
              {rows.map((id, index) => (
                <PurchaseItemsTableRow
                  key={index}
                  index={index}
                  onCountChange={() => setTotalCount(calculateTotalCount())}
                  onDeleteClick={() => {
                    setRows(rows.filter((index) => index !== id));
                    setTotalCount(calculateTotalCount());

                  }}
                />
              ))}
              
            </div>
            {/* buttons */}
            <div className="flex flex-row gap-1 h-12 text-white p-2 bg-[#333033] rounded-b">
                <div className="flex flex-1 gap-1 text-[10px] text-white place-items-end text-center "></div>
                <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center border-r border-r-[#464447]">
                    Позиций <br />
                    {rows.length}
                </div>
                <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                    Количество (объем) <br />
                    {totalCount}
                </div>
            </div>
        </div>

  );
}

function calculateTotalCount(): number {
  const inputs = document.getElementsByName("purchase_item_count");
  let count = 0;
  for (let i = 0; i < inputs.length; i++) {
    let value = (inputs[i] as HTMLInputElement).value;
    if (value !== null && value !== "") {
      count += Number(value);
    }
  }
  return count;
}

function _getRowId(): number {
  return Date.now();
}


export default PurchaseItemsTable;