import React, {useState, useEffect} from "react";

import { useLoaderData, useNavigate} from "react-router-dom";
import { PlanPosition,PlanPositionDraft } from "./types";
import { Plan } from "../types";

import Add from "../Toolbar/Add";
import Copy from "../Toolbar/Copy";
import "../Toolbar/Icon.css";

export const loadPlanPositionList = async ({
  params,
}: any): Promise<PlanPosition[]> => {
  return fetch(
    "https://itzakupki.ru/api/backend/v1/plan_positions/?plan_guid=" +
      encodeURIComponent(params.plan_guid),
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Failed to fetch plan positions");
      }
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};

const PlanPositionListPage: React.FC = () => {
  const positions = useLoaderData() as PlanPosition[];
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

 // Функция для обработки изменения состояния отдельного checkbox
  const handleCheckboxChange = (ordinal_namber: number) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(ordinal_namber)) {
        return prevSelectedItems.filter((itemId) => itemId !== ordinal_namber);
      } else {
        return [...prevSelectedItems, ordinal_namber];
      }
    });
  };

  // Функция для обработки изменения состояния "Select All"
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedItems(positions.map((item) => item.ordinal_number)); // Выбираем все элементы
    } else {
      setSelectedItems([]); // Сбрасываем выбор всех элементов
    }
  };

  // При изменении данных в таблице обновляем выбор "Select All"
  useEffect(() => {
    if (selectAll && selectedItems.length !== positions.length) {
      setSelectedItems(positions.map((item) => item.ordinal_number));
    }
  }, [positions, selectAll]);

  const handleDeleteClick = () => {
    alert("Вы хотите удалить позиции:" + selectedItems)
    console.log('Selected items:', selectedItems); 
  };

  let initialValue = 0;
  let sum = positions.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue.maximum_contract_price),
    initialValue,
  );

  // const resultEisP = positions.filter((pos) => pos.eis.position_stage == "План закупки в ЕИС")

  // const resultDraftP = positions.filter((pos) => pos.draft.position_stage == "Редактирование")

  return (
    <div className="h-full flex flex-col bg-[#181818]">

      <div className="bg-[#111111]">
        <div className=" h-24 mx-6 flex flex-row gap-x-5 ">
          <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
            <div className="text-xs text-[#8A8A8A] ">Реестровый номер</div>
            <div className="text-xs text-white ">
              { '516516' }
            </div>
          </div>
          <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
            <div className="text-xs text-[#8A8A8A] ">Период планирования</div>
            <div className="text-xs text-white ">2024</div>
          </div>
          <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
            <div className="text-xs text-[#8A8A8A] ">Количество позиций</div>
            <div className="text-xs text-white ">{positions.length}</div>
          </div>
          <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
            <div className="text-xs text-[#8A8A8A] ">Запланировано закупок</div>
            <div className="text-xs text-white ">
              {Number(
                sum
                ).toLocaleString("ru-RU", { minimumFractionDigits: 2 }) +
                  " ₽"
                }
              </div>
          </div>
          <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
            <div className="text-xs text-[#8A8A8A] ">Размещено закупок</div>
            <div className="text-xs text-white ">0,00 ₽ (0%)</div>
          </div>
          <div className="w-60 my-auto content-center ">
            <div className="text-xs text-[#8A8A8A] ">Запланировано закупок у МСП</div>
            <div className="text-xs text-white ">500 000,00 ₽ (20%)</div>
          </div>
          
        </div>
        <div className="flex flex-row cursor-pointer">
          <div className="w-5"></div>
          <span className="w-60 h-7 content-center text-center text-xs text-[#8A8A8A] rounded-t hover:bg-[#181818] hover:text-sm">
            Редактирование
          </span>
          <span className="w-60 h-7 content-center text-center text-xs text-[#8A8A8A] rounded-t hover:bg-[#181818] hover:text-sm">
            Подготовка к размещению
          </span>
          <span className="w-60 h-7 content-center text-center text-xs text-[#8A8A8A] rounded-t hover:bg-[#181818] hover:text-sm">
            Отправление в ЕИС
          </span>
          <span className="w-60 h-7 content-center text-center text-sm text-white rounded-t bg-[#181818]">
            План закупки в ЕИС
          </span>
        </div>
      </div>

    
      {/* // body */}
      <div className="plan-positions flex p-5 h-[calc(100vh_-_96px_-_28px)]">
          {/* table */}
          <div className="flex flex-col w-full bg-[#1D1C1E] rounded">
              {/* toolbar */}
              <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t">
                <div className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                  <input 
                    type="checkbox" 
                    className="p-2" 
                    checked={selectAll}
                    onChange={handleSelectAllChange} 
                  />
                </div>

                <Add color={'#FFFFFF'}/>
                <Copy color={'#FFFFFF'}/>         
                      
                <button className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]"
                  onClick={handleDeleteClick} >
                  <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" id="trash-alt">
                    <path fill="#FFFFFF" d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"></path>
                  </svg>
                </button>

              </div>
              {/* name */}
              <div className="flex flex-row gap-1 h-24 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                  <div className="grid w-8 place-items-center text-center"></div>
                  <div className="grid w-12 place-items-center text-center">№</div>
                  <div className="grid w-24 place-items-center text-center">Стадия</div>
                  <div className="grid w-24 place-items-center text-center">Статус</div>
                  <div className="flex-1 grid place-items-center text-center min-w-44">Предмет договора</div>
                  <div className="grid w-28 place-items-center text-center">Начальная (максимальная) цена договора</div>
                  <div className="grid w-24 place-items-center text-center">Дата (период) размещения извещения</div>
                  <div className="grid w-24 place-items-center text-center">Срок исполнения договора</div>
                  <div className="grid w-44 place-items-center text-center">Способ закупки</div>
                  <div className="grid w-24 place-items-center text-center">Статус в плане закупки</div>
                  <div className="grid w-20 place-items-center text-center">Тип объекта закупки</div>
                  <div className="grid w-20 place-items-center text-center">Подразделение</div>
                  <div className="grid w-20 place-items-center text-center">Статья</div>
                  <div className="grid w-28 place-items-center text-center">Номер в системе</div>
                  <div className="grid w-20 place-items-center text-center">Дата последнего изменения</div>
                  <div className="grid w-12 place-items-center text-center">ДД</div>
              </div>
              
              
              {/* data */}
              <div className="flex flex-col overflow-y-auto gap-1 my-1 h-dvh">
                              
                {/* Вывод позиций */}
                <>
                  {
                  positions.map((response, index) => {
                  const planPosition = response;

                  return (
                    <div className={
                      selectedItems.includes(planPosition.ordinal_number) 
                        ? 
                        (
                          "flex flex-row gap-1 mx-1 cursor-pointer bg-[#373538] hover:bg-[#373538] text-white text-[11px] rounded"
                        )
                        :
                        (
                          "flex flex-row gap-1 mx-1 cursor-pointer bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                        )
                    
                      }
                      key={index}
                      onDoubleClick={() =>
                        navigate(
                          "/hub/plans/" +
                            planPosition.plan_guid +
                            "/positions/" +
                            planPosition.guid +
                            "/edit"
                        )
                      }
                      onClick={() => handleCheckboxChange(planPosition.ordinal_number)}
                    >
                      <div className="grid h-24 w-8 place-items-center text-center">
                        <input 
                          type="checkbox" 
                          checked={selectedItems.includes(planPosition.ordinal_number)}
                          onChange={() => handleCheckboxChange(planPosition.ordinal_number)}
                        />
                      </div>
                      <div className="grid h-24 w-12 place-items-center text-center text-[#8A8A8A]">
                        {planPosition.ordinal_number}
                        </div>
                      <div className="grid h-24 w-24 place-items-center text-center bg-[#A63636] rounded">
                        {planPosition.position_stage}
                      </div>
                      <div className="grid h-24 w-24 place-items-center text-center bg-[#A63636] rounded">
                        {planPosition.position_status}
                      </div>
                      <div className="grid h-24 flex-1 place-items-center justify-items-start text-left min-w-44 px-2 ">
                        <p className="fourrow">{planPosition.contract_subject}</p>
                      </div>
                      <div className="grid h-24 w-28 place-items-center justify-items-end text-right px-2 ">
                        {Number(
                          planPosition.maximum_contract_price
                        ).toLocaleString("ru-RU", { minimumFractionDigits: 2 }) +
                          " " +
                          CURRENCY.get(
                            String(planPosition.currency_digital_code)
                        )}
                      </div>
                      <div className="grid h-24 w-24 place-items-center justify-items-end text-right px-2 ">
                        {planPosition.purchase_planned_date === null ? (
                          <>
                            {MONTHS[
                              Number(planPosition.purchase_period_month) - 1
                            ] + " "}
                            {planPosition.purchase_period_year}
                          </>
                        ) : (
                          planPosition.purchase_planned_date
                            .split("-")
                            .reverse()
                            .join("-")
                            .replace(/[\-]/g, ".")
                        )}
                      </div>
                      <div className="grid h-24 w-24 place-items-center justify-items-end text-right px-2 ">
                        {
                          <>
                            {MONTHS[
                              Number(
                                planPosition.contract_end_date?.slice(5, 7)
                              ) - 1
                            ] + " "}
                            {planPosition.contract_end_date?.slice(0, 4)}
                          </>
                        }
                      </div>
                      <div className="grid h-24 w-44 place-items-center text-center">
                        {planPosition.purchase_method_name}
                      </div>
                      <div className={
                          planPosition.status === "P"
                            ? "grid h-24 w-24 place-items-center text-center bg-[#2F8A43] rounded"
                            : "grid h-24 w-24 place-items-center text-center bg-[#A63636] rounded"
                        }
                      >
                        {POSITION_STATUS.get(String(planPosition.status))}
                      </div>
                      <div className="grid h-24 w-20 place-items-center text-center">
                        Тут будет
                        <br />
                        "Тип объекта закупки"
                      </div>
                      <div className="grid h-24 w-20 place-items-center text-center">
                        Тут будет
                        <br />
                        "Подразделение"
                      </div>
                      <div className="grid h-24 w-20 place-items-center text-center">
                        Тут будет
                        <br />
                        "Статья"
                      </div>
                      <div className="grid h-24 w-28 place-items-center text-center text-[#8A8A8A]">
                        Тут будет
                        <br />
                        "Номер в система"
                      </div>
                      <div className="grid h-24 w-20 place-items-center text-center text-[#8A8A8A]">
                        {planPosition.sys_updated_at
                          ?.slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")
                          .replace(/[\-]/g, ".")}
                        <br />
                        {planPosition.sys_updated_at?.slice(11, 16)}
                      </div>
                      <div className="grid h-24 w-12 place-items-center text-center">
                        <div className="text-xs text-white bg-[#3a3a3a] w-7 h-7 rounded-full mx-auto my-auto content-center  ">
                          AA
                        </div>
                      </div>
                    </div>
                  );
                })}

                </> 
              </div>

              {/* buttons */}
              <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10">
                <button className="button">+ Создать позицию</button>
                <button className="button">Скопировать</button>
                <button className="button">Печать</button>
                <button className="button">Скачать</button>
              </div>
          </div>
      </div>
    </div>
  );
};

const TABLE_TH_CLS = "p-2 font-normal text-[10px] text-zinc-500";
const TABLE_TD_CLS = "p-2 text-center text-[11px] text-white";
const TABLE_TD_ALEFT_CLS = "p-2 text-left text-[11px] text-white";
const TABLE_TD_ARIGHT_CLS = "p-2 text-right text-[11px] text-white";
const TABLE_TD_GREEN_CLS = "py-2 text-center text-[11px] text-white bg-[#2F8A43] rounded-md";

const MONTHS = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

const POSITION_STATUS = new Map([
  ["P", "Размещена"],
  ["A", "Аннулирована"],
  ["N", "Новая"],
  ["C", "Изменена"],
]);

const CURRENCY = new Map([
  ["643", "₽"],
  ["156", "¥"],
  ["978", "€"],
  ["840", "$"],
]);

export default PlanPositionListPage;
