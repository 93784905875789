import MoneyInput from "../form/MoneyInput";

type Props = {
  value: number;
  onChange?: (value: number) => void;
};

const CurrencyExchangeRateInput: React.FC<Props> = (props) => {
  return (
    <>
      <span className="form-label">Курс валюты</span>
      <MoneyInput value={props.value} onChange={props.onChange} className=" form-input bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"/>
    </>
  );
};

export default CurrencyExchangeRateInput;
