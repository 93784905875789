import React, { FormEvent, useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

type Org = {
  id: string;
  name: string;
  inn: string;
  kpp: string;
  address: string;
};

const NEW_ORG: Org = {
  id: "new",
  name: "",
  inn: "",
  kpp: "",
  address: "",
};

export const loadOrganization = async ({ params }: any): Promise<Org> => {
  const id = params["id"];

  if (id === "new") {
    return new Promise((resolver) => resolver(NEW_ORG));
  }

  return fetch(
    "https://itzakupki.ru/api/auth/v1/organizations/?id=" +
      encodeURIComponent(id),
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      if (response.ok) {
        // success
        return response.json();
      } else {
        throw new Error("Org not found: " + id);
      }
    })
    .then((orgs) => orgs[0])
    .then((org) => {
      return {
        id: org["id"],
        name: org.name,
        inn: String(org.inn),
        kpp: String(org.kpp),
        address: org.address,
      };
    })
    .catch((e) => {
      console.error(e);
      return NEW_ORG;
    });
};

const RegisterForm: React.FC = () => {
  const org = useLoaderData() as Org;
  const [error, setError] = useState<string | null>(null);
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [orgName, setOrgName] = useState(org.name);
  const [orgInn, setOrgInn] = useState(org.inn);
  const [orgKpp, setOrgKpp] = useState(org.kpp);
  const [orgAddress, setOrgAddress] = useState(org.address);

  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneAdd, setPhoneAdd] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const isOrgDataValid =
      orgName.length > 0 &&
      orgInn.length > 0 &&
      orgKpp.length > 0 &&
      orgAddress.length > 0;

    setValid(
      isOrgDataValid &&
        name.length > 1 &&
        lastname.length > 1 &&
        phone.length > 3 &&
        isEmail(email) &&
        password.length > 3 &&
        password === password2
    );
  }, [
    orgName,
    orgInn,
    orgKpp,
    orgAddress,
    name,
    lastname,
    password,
    password2,
    phone,
    email,
  ]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    let url;
    let body;
    if (org.id === "new") {
      url = "https://itzakupki.ru/api/auth/v1/organizations/create/";
      body = {
        name: orgName,
        inn: orgInn,
        kpp: orgKpp,
        ogrn: 0, // todo: hardcoded?
        address: orgAddress,
        admin_phone: phone,
        admin_phone_add: phoneAdd,
        admin_email: email,
        admin_name: name,
        admin_lastname: lastname,
        admin_patronymic: patronymic,
        admin_password: password,
      };
    } else {
      url = "https://itzakupki.ru/api/auth/v1/organizations/users/create/";
      body = {
        name: name,
        lastname: lastname,
        patronymic: patronymic,
        password: password,
        phone: phone,
        phone_add: phoneAdd,
        email: email,
        // role_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6", // todo: hardcoded?
        organization_id: org.id,
      };
    }

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          // success
          return response.json();
        } else {
          throw new Error("Произошла ошибка");
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <h5 className="mb-2 text-lg text-white">
        {org.id === "new" ? "Заявка на добавление" : "Регистрация организации"}
      </h5>
      <form method="post" onSubmit={handleSubmit}>
        <fieldset disabled={isLoading}>
          <p className="mt-2 text-sm text-center text-zinc-500">
            Данные организации
          </p>
          <div
            className={
              "flex flex-col mb-2" + (org.id === "new" ? " hidden" : "")
            }
          >
            <label className="mb-1 text-sm text-zinc-500">Поиск</label>
            <input
              name="query"
              type="text"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              placeholder="Наименование организации / ИНН / ОГРН"
              value={orgName}
              disabled={true}
            />
          </div>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">Наименование</label>
            <input
              name="name"
              type="text"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              disabled={org.id !== "new"}
            />
          </div>
          <div className="flex flex-row mb-2">
            <div className="flex flex-col basis-3/5 me-2">
              <label className="flex flex-col mb-1 text-sm text-zinc-500">
                ИНН
              </label>
              <input
                name="inn"
                type="text"
                className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
                value={orgInn}
                onChange={(e) => setOrgInn(e.target.value)}
                disabled={org.id !== "new"}
              />
            </div>
            <div className="flex flex-col basis-2/5">
              <label className="flex flex-col mb-1 text-sm text-zinc-500">
                КПП
              </label>
              <input
                name="kpp"
                type="text"
                className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
                value={orgKpp}
                onChange={(e) => setOrgKpp(e.target.value)}
                disabled={org.id !== "new"}
              />
            </div>
          </div>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">Адрес</label>
            <input
              name="address"
              type="text"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              value={orgAddress}
              onChange={(e) => setOrgAddress(e.target.value)}
              disabled={org.id !== "new"}
            />
          </div>
          <p className="mt-4 text-sm text-center text-zinc-500">
            Данные пользователя
          </p>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">
              Фамилия <span className="text-sm text-red-600">*</span>
            </label>
            <input
              name="lastname"
              type="text"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              required={true}
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">
              Имя <span className="text-sm text-red-600">*</span>
            </label>
            <input
              name="firstname"
              type="text"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              required={true}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">Отчество</label>
            <input
              name="patronymic"
              type="text"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              value={patronymic}
              onChange={(e) => setPatronymic(e.target.value)}
            />
          </div>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">
              Телефон <span className="text-sm text-red-600">*</span>
            </label>
            <div className="flex flex-row items-center">
              <input
                name="phone"
                type="text"
                className="grow p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
                required={true}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <div className="mx-2 text-xs text-zinc-500">Доб.</div>
              <input
                name="phone_add"
                type="text"
                className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
                value={phoneAdd}
                onChange={(e) => setPhoneAdd(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">
              Электронная почта <span className="text-sm text-red-600">*</span>
            </label>
            <input
              name="email"
              type="email"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              autoComplete="email"
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">
              Пароль <span className="text-sm text-red-600">*</span>
            </label>
            <input
              name="password"
              type="password"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              required={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col mb-2">
            <label className="mb-1 text-sm text-zinc-500">
              Повторите пароль <span className="text-sm text-red-600">*</span>
            </label>
            <input
              name="password2"
              type="password"
              className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
              required={true}
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </div>
          <button
            className="w-full my-4 p-2 bg-fuchsia-600 rounded-full shadow-md text-sm text-white disabled:bg-fuchsia-900 disabled:text-gray-400"
            disabled={!isValid}
          >
            Зарегистрировать
          </button>
          <p className="mb-2 text-sm text-zinc-600">
            Заполняя форму, вы соглашаетесь на{" "}
            <Link to="" className="text-sky-600 hover:text-sky-400 underline">
              обработку персональных данных
            </Link>{" "}
            и получение информационных сообщений от ООО «Технологии закупок»
          </p>
          <p className="mb-0 text-sm text-zinc-600">
            Организация уже зарегистрирована в системе?{" "}
            <Link to="/auth" className="text-sky-600 hover:text-sky-400">
              Войти
            </Link>
          </p>
        </fieldset>
      </form>
      {error && (
        <p className="mb-0 mt-2 text-sm text-red-600" role="alert">
          {error}
        </p>
      )}
    </>
  );
};

export default RegisterForm;
