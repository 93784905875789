import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../services/auth";

type AuthContextProps = {
  user: User | null;
  login: (user: User) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

type AuthProviderProps = {
  user: User | null;
  children: React.ReactElement;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ user, children }) => {
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState(user);

  console.log("render auth provider");

  const login = (user: User) => {
    setLoggedInUser(user);
    localStorage.setItem("refresh_time", Date.now().toString());
    navigate("/hub");
  };

  const logout = () => {
    setLoggedInUser(null);
    localStorage.clear();
    navigate("/", { replace: true });
  };

  return (
    <AuthContext.Provider value={{ user: loggedInUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
