import React from "react";
import "./Icon.css";

const Add = ({ color }: any) => {
    return (
        <div className="grid w-6 h-6 rounded-full place-items-center hover:bg-[#656365]">
            <svg className="plus-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="plus" >
                <path fill={color} d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z">
                </path>
            </svg>
        </div>
    );
}

export default Add;