import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, defer, RouterProvider } from "react-router-dom";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";

import HomePage from "./pages/Home";

import AuthLayout from "./pages/Auth/AuthLayout";
import LoginForm from "./pages/Auth/LoginForm";
import RestoreForm from "./pages/Auth/RestoreForm";
import RegisterForm, { loadOrganization } from "./pages/Auth/RegisterForm";
import SearchForm from "./pages/Auth/SearchForm";

import { getUserData } from "./services/auth";

import HubPage from "./pages/Hub";
import HubLayout from "./pages/Hub/HubLayout";
import HubInnerLayout from "./pages/Hub/HubLayoutInner";

import PlanListPage, { loadPlanList } from "./pages/Hub/Plans";
import PlanPositionListPage, {
  loadPlanPositionList,
} from "./pages/Hub/Plans/Positions";
import PlanPositionEditPage, {
  loadPlanPosition,
} from "./pages/Hub/Plans/Positions/Edit";
import OrgPage from "./pages/Hub/Org";
import OrgListsPage from "./pages/Hub/Org/Lists";
import OrgPurchaseMethodsPage from "./pages/Hub/Org/PurchaseMethods";
import OrgStructurePage from "./pages/Hub/Org/Structure";
import OrgUsersPage from "./pages/Hub/Org/Users";
import OrgRolesPage from "./pages/Hub/Org/Roles";

const router = createHashRouter([
  {
    path: "/",
    element: <RootLayout />,
    loader: () => defer({ userPromise: getUserData() }),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            index: true,
            element: <LoginForm />,
          },
          {
            path: "restore",
            element: <RestoreForm />,
          },
          {
            path: "search",
            element: <SearchForm />,
          },
          {
            path: "register/:id",
            loader: loadOrganization,
            element: <RegisterForm />,
          },
        ],
      },
      {
        path: "hub",
        element: <HubLayout />,
        children: [
          {
            element: <HubInnerLayout />,
            children: [
              {
                index: true,
                element: <HubPage />,
              },
              {
                path: "org",
                element: <OrgPage />,
              },
              {
                path: "org/users",
                element: <OrgUsersPage />,
              },
              {
                path: "org/structure",
                element: <OrgStructurePage />,
              },
              {
                path: "org/roles",
                element: <OrgRolesPage />,
              },
              {
                path: "org/methods",
                element: <OrgPurchaseMethodsPage />,
              },
              {
                path: "org/lists",
                element: <OrgListsPage />,
              },
              {
                path: "plans",
                loader: loadPlanList,
                element: <PlanListPage />,
              },
              {
                path: "plans/:plan_guid/positions",
                loader: loadPlanPositionList,
                element: <PlanPositionListPage />,
              },
            ],
          },
          {
            path: "plans/:plan_guid/positions/:pos_guid/edit",
            loader: loadPlanPosition,
            element: <PlanPositionEditPage />,
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
