type Props = {
  value: string;
  onChange: (value: string) => void;
};

const CurrencySelector: React.FC<Props> = ({ value, onChange }) => {
  return (
    <>
      <span className="form-label">Вылюта договора</span>
      <select
        className="form-select focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        <option value="rub">Российский рубль</option>
        <option value="usd">Доллар США</option>
        <option value="eur">Евро</option>
        <option value="cny">Китайский юань</option>
      </select>
    </>
  );
};

export default CurrencySelector;
