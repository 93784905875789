type Props = {
  handleSave: () => void;
};

const Footer: React.FC<Props> = ({ handleSave }) => {
  return (
    <footer className="">
      <div className="flex flex-row gap-2 p-2 bg-zinc-800">
        <button className="button [&]:bg-green-700">Проверить</button>
        <button className="button" disabled>
          Готово
        </button>
        <button className="button" disabled>
          Направить на подготовку к размещению
        </button>
        <button className="button" disabled>
          Редактировать
        </button>
        <span className="grow"></span>
        <button className="button" onClick={handleSave}>
          Сохранить
        </button>
        <button className="button">Импорт данных</button>
        <button className="button">Удалить</button>
        <button className="button">Скопировать</button>
        <button className="button">Скачать</button>
        <button className="button">Печать</button>
        <button className="button">Закрыть</button>
      </div>
    </footer>
  );
};

export default Footer;
