import Section from "../Section";

type Props = {
  start: Date;
  onStartDateChange: (value: Date) => void;
  end: Date;
  onEndDateChange: (value: Date) => void;
  isStartPreciseDate: boolean;
  onIsStartPreciseDateChange: (value: boolean) => void;
};

const PurchaseDatesInfo: React.FC<Props> = (props) => {
  return (
    <Section title="Информация о планах проведения закупки">
      <div className="form-label">
        Планируемая дата или период размещения извещения о закупке
      </div>
      <div className="flex flex-row mb-2 items-center">
        <div className="md:mr-5">
          <select
            className="form-select"
            value={props.isStartPreciseDate ? -1 : props.start.getFullYear()}
            onChange={(e) => {
              const newYear = e.target.value;
              props.onStartDateChange(
                new Date(Number(newYear), props.start.getMonth(), 1)
              );
              props.onIsStartPreciseDateChange(false);
            }}
          >
            {_YEARS}
          </select>
        </div>
        <div className="md:mr-5">
          <select
            className="form-select"
            value={props.isStartPreciseDate ? -1 : props.start.getMonth()}
            onChange={(e) => {
              const newMonth = e.target.value;
              props.onStartDateChange(
                new Date(props.start.getFullYear(), Number(newMonth), 1)
              );
              props.onIsStartPreciseDateChange(false);
            }}
          >
            {_MONTHS}
          </select>
        </div>
        <div className="md:mr-5">
          <span className="form-label">/</span>
        </div>
        <div>
          <input
            type="date"
            className="form-input focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
            value={props.isStartPreciseDate ? _formatDate(props.start) : ""}
            onChange={(e) => {
              const date = e.target.value
                ? new Date(e.target.value)
                : new Date();
              props.onStartDateChange(date);
              props.onIsStartPreciseDateChange(Boolean(e.target.value));
            }}
            style={{ colorScheme: "dark" }}
          />
        </div>
      </div>
      <div className="form-label">Срок исполнения договора</div>
      <div className="flex flex-row">
        <div className="md:mr-5">
          <select
            className="form-select"
            value={props.end.getFullYear()}
            onChange={(e) => {
              const newYear = e.target.value;
              props.onEndDateChange(
                new Date(Number(newYear), props.end.getMonth(), 1)
              );
            }}
          >
            {_YEARS}
          </select>
        </div>
        <div>
          <select
            className="form-select"
            value={props.end.getMonth()}
            onChange={(e) => {
              const newMonth = e.target.value;
              props.onEndDateChange(
                new Date(props.end.getFullYear(), Number(newMonth), 1)
              );
            }}
          >
            {_MONTHS}
          </select>
        </div>
      </div>
    </Section>
  );
};

const _MONTHS = [
  <option key="-1" value="-1" disabled>
    Месяц
  </option>,
  <option key="0" value="0">
    Январь
  </option>,
  <option key="1" value="1">
    Февраль
  </option>,
  <option key="2" value="2">
    Март
  </option>,
  <option key="3" value="3">
    Апрель
  </option>,
  <option key="4" value="4">
    Май
  </option>,
  <option key="5" value="5">
    Июнь
  </option>,
  <option key="6" value="6">
    Июль
  </option>,
  <option key="7" value="7">
    Август
  </option>,
  <option key="8" value="8">
    Сентябрь
  </option>,
  <option key="9" value="9">
    Октябрь
  </option>,
  <option key="10" value="10">
    Ноябрь
  </option>,
  <option key="11" value="11">
    Декабрь
  </option>,
];

const _PREVIOUS_YEAR = new Date().getFullYear() - 1;

const _YEARS = [
  <option key="-1" value="-1" disabled>
    Год
  </option>,
  ...Array.from({ length: 10 }, (_, index) => {
    const year = _PREVIOUS_YEAR + index;
    return (
      <option key={year} value={year}>
        {year}
      </option>
    );
  }),
];

const _formatDate = (date: Date) => {
  let month = String(date.getMonth() + 1);
  if (month.length < 2) month = "0" + month;

  let day = String(date.getDate() + 1);
  if (day.length < 2) day = "0" + day;

  return `${date.getFullYear()}-${month}-${day}`;
};

export default PurchaseDatesInfo;
