import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";
import { Plan } from "./types";

import Add from "./Toolbar/Add";
import "./Toolbar/Icon.css";

type ApiResponse = {
  message: Plan[];
};

export const loadPlanList = async (): Promise<Plan[]> => {
  return fetch("https://itzakupki.ru/api/backend/v1/plans", {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Failed to fetch plans");
      }
    })
    .then((response: ApiResponse) => response.message)
    .catch((e) => {
      console.error(e);
      return [];
    });
};

const PlanListPage: React.FC = () => {
  const [planCounts, setPlanCounts] = useState<{ [key: string]: number | null }>({});

  const plans = useLoaderData() as Plan[];
  const navigate = useNavigate();

  const planPositionsCount = (plan_guid: string) => {
    fetch(
      `https://itzakupki.ru/api/backend/v1/plan_positions_count/?plan_guid=${encodeURIComponent(plan_guid)}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Ошибка");
        }
        return response.json();
      })
      .then((data) => {
        setPlanCounts((prevCounts) => ({
          ...prevCounts,
          [plan_guid]: data.count,
        }));
      })
      .catch((error) => {
        console.error("Ошибка при получении количества позиций плана:", error);
        setPlanCounts((prevCounts) => ({
          ...prevCounts,
          [plan_guid]: null,
        }));
      });
  };

  useEffect(() => {
    plans.forEach((plan) => {
      if (!planCounts[plan.guid]) {
        planPositionsCount(plan.guid);
      }
    });
  }, [plans]);

  return (
    <div className="h-full flex flex-col">
      <div className="bg-[#111111]">
        <div className="w-auto h-24 mx-6 flex flex-row gap-x-5 ">
          <div className="w-60 h-24 my-auto content-center ">
            <p className="text-xs text-zinc-500">Подразделение</p>
            <p className="text-xs text-white">Управление закупок</p>
          </div>
        </div>
        <div className="flex flex-row cursor-pointer ">
          <div className="w-5"></div>
          <span className="w-60 h-7 content-center text-center text-sm text-white rounded-t bg-[#181818]">
            Реестр планов закупки
          </span>
        </div>
      </div>


      {/* body */}
      <div className="flex bg-white dark:bg-[#181818] p-5 duration-200 ease-in-out h-dvh">
        {/* table */}
        <div className="flex flex-col w-full min-w-fit bg-[#1D1C1E] rounded">
          {/* toolbar */}
          <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t ">
            <Add color={'#FFFFFF'}/>
          </div>
          {/* name */}
          <div className="flex flex-row gap-1 h-16 bg-[#262427] text-[#8A8A8A] text-[10px] p-1 ">
            <div className="grid w-12 place-items-center text-center">№</div>
            <div className="grid w-28 place-items-center text-center">Номер плана в ЕИС</div>
            <div className="grid w-20 place-items-center text-center">Версия плана</div>
            <div className="grid w-28 place-items-center text-center">Статус</div>
            <div className="flex-1 grid place-items-center text-center min-w-56">Вид плана закупки</div>
            <div className="grid w-24 place-items-center text-center">Количество позиций</div>
            <div className="grid w-24 place-items-center text-center">Период планирования</div>
            <div className="grid w-36 place-items-center text-center">Период действия плана</div>
            <div className="grid w-24 place-items-center text-center">Дата размещения</div>
            <div className="grid w-24 place-items-center text-center">Дата утверждения</div>
            <div className="grid w-24 place-items-center text-center">Дата обновления</div>
          </div>
          {/* date */}
          <div className="flex flex-col overflow-y-auto gap-1 my-1 grow">
            {plans.map((plan, index) => (
              <div
                key={plan.guid}
                onClick={() => navigate("/hub/plans/" + plan.guid + "/positions")}
                className="flex flex-row gap-1 mx-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded cursor-pointer"
              >
                <div className="grid h-12 w-12 place-items-center text-center text-[#8A8A8A]">{index + 1}</div>
                <div className="grid h-12 w-28 place-items-center text-center">{plan.registration_number}</div>
                <div className="grid h-12 w-20 place-items-center text-center">{plan.version}</div>
                <div className="grid h-12 w-28 place-items-center text-center bg-[#2F8A43] rounded">Размещен</div>
                <div className="grid h-12 flex-1 items-center text-left min-w-56 px-1">
                  <p>
                    <a
                      onClick={() => navigate("/hub/plans/" + plan.guid + "/positions")}
                      className=""
                    >
                      {plan.plan_type === "COMMODITY"
                        ? "План закупки товаров (работ, услуг)"
                        : "План закупки инновационной продукции, высокотехнологичной продукции и лекарственных средств"}
                    </a>
                  </p>
                </div>
                <div className="grid h-12 w-24 place-items-center text-center">
                  {planCounts[plan.guid] !== undefined
                    ? `${planCounts[plan.guid]}`
                    : 'Загрузка...'}
                </div>
                <div className="grid h-12 w-24 place-items-center text-center">{plan.reporting_year}</div>
                <div className="grid h-12 w-36 place-items-center text-center">
                  {plan.start_date.split("-").reverse().join("-").replace(/[\-]/g, '.')} - {plan.end_date.split("-").reverse().join("-").replace(/[\-]/g, '.')}
                </div>
                <div className="grid h-12 w-24 place-items-center text-center">
                  {plan.publication_date_time?.slice(0, 10).split("-").reverse().join("-").replace(/[\-]/g, '.')}
                  <br />
                  {plan.publication_date_time?.slice(11, 16)}
                </div>
                <div className="grid h-12 w-24 place-items-center text-center">{plan.approve_date.split("-").reverse().join("-").replace(/[\-]/g, '.')}</div>
                <div className="grid h-12 w-24 place-items-center text-center">
                  {plan.sys_updated_at?.slice(0, 10).split("-").reverse().join("-").replace(/[\-]/g, '.')}
                  <br />
                  {plan.sys_updated_at?.slice(11, 16)}
                </div>
              </div>
            ))}
          </div>
          {/* buttons */}
          <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10">
            <button className="button">+ Добавить план</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanListPage;